<template>
  <div class="GlobalFooter">
    <div class="top" >
      <div class="menus" >
        <div class="menus-item" v-for="(menu,index) in menus" :key="index">
          <div class="menu" :ref="'menu-'+index" :title="menu.title" :style="{background:clickTimes>100?'green':''}" @click="caidan(index)">{{menu.title}}</div>
          <div class="submenu" v-if="menu.children" :ref="'submenu-'+index">
            <div class="submenu-item" :title="menu2.title" v-for="(menu2,index2) in menu.children" :key="index2" @click="goto(menu2)">
              {{menu2.title}}
            </div>
          </div>
        </div>
      </div>
      <div class="QR" v-if="getFileAccessHttpUrl($store.state.config.sysQrCode)">
        <div class="QRimage">
          <img :src="getFileAccessHttpUrl($store.state.config.sysQrCode)" height="162px" width="162px" alt="">
        </div>
        <div class="text">
          {{language=="CN"?"扫码关注微信公众号":"Scan QR code to follow WeChat official account"}}
        </div>
      </div>
    </div>
    <div class="bottom">
    <div class="bottom-top">
      <div class="LOGO">
        <img :src="getFileAccessHttpUrl($store.state.config.sysCompanyLogo)" alt="">
      </div>
      <div class="right" @click="aaa()">
        ©2022 {{ language=="CN" ? $store.state.config.sysWebsiteTitle : $store.state.config.sysWebsiteTitleEn }} 版权所有 ICP备{{ $store.state.config.sysIcpPrepared }}号
      </div>
    </div>
    <div class="bottom-bot">
      <div style="width:300px;margin:0 auto; padding:20px 0;">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33032602100452" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <!-- <img src="../../assets/tbiao.png" style="float:left;"/> -->
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33032602100452号</p>
        </a>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
import {getFileAccessHttpUrl} from "@/api/manage";

export default {
  name: "GlobalFooter",
  data() {
    return {
      clickTimes:0
    }
  },
  created() {
    if(window.screen.width>=1200){
      console.log(123)
      this.$nextTick(()=>{
        console.log("this.$refs.menu.style",this.$refs )
        let menus = Object.keys(this.$refs).filter(i=>i.indexOf('menu'))
        let submenus = Object.keys(this.$refs).filter(i=>i.indexOf('submenu'))
        menus.forEach(i=>{
          if(this.$refs[i][0]){
            this.$refs[i][0].style.maxWidth= "calc(100vw / 7 - 100px)"
          }
        })
        submenus.forEach(i=>{
          if(this.$refs[i][0]){
            this.$refs[i][0].style.maxWidth= "calc(100vw / 7 - 100px)"
          }
        })
        // this.$refs.menu.style.maxWidth = "calc(100vw / 7 - 100px)"
        // this.$refs.submenu.style.maxWidth = "calc(100vw / 7 - 100px)"
      })
    }
  },
  computed:{
    menus(){
      let menus = this.$store.getters.getMenus.filter(i=>i.meta&&!i.redirect).map(i=>{
        let obj = {}
        obj.title = this.language=="CN"?i.meta.name:i.meta.nameEn
        obj.type = i.meta.type
        obj.path = i.path
        if(i.children){
          obj.children = this.menusFilter(i)
        }
        return obj
      })
      if(!menus.length){
        return []
      }
      console.log("menus",menus)
      menus[0].children = [
        {
          title:this.language=="CN"?"公司简介":"About Us",
          initialPosition:"Introduction"
        },{
          title:this.language=="CN"?"产品中心":"Product Center",
          initialPosition:"product"
        },{
          title:this.language=="CN"?"研发创新":"R&D Innovation",
          initialPosition:"research"
        },{
          title:this.language=="CN"?"新闻中心":"News Center",
          initialPosition:"press"
        },{
          title:this.language=="CN"?"应用领域":"Applications",
          initialPosition:"application"
        },
      ]
      let result = menus.filter(i=>i.children)
      if(result.length>=7){
        let othersArr = result.slice(6)
        let currentArr = result.slice(0,6)
        console.log("othersArr",othersArr,"currentArr",currentArr)
        currentArr.push({
          title:this.language=='CN'?"其他":'Others',
          children:othersArr.map(i=>i.children).flat()
        })
        console.log("currentArr",currentArr)
        result = currentArr
      }
      return result
    },
    language(){
      return this.$store.getters.language
    }
  },
  methods: {
    getFileAccessHttpUrl,
      aaa() {
          console.log(this.$store.state.config)
      },
    menusFilter(item){
      let arr = item.children.filter(i=>i.meta&&!i.redirect).map(i=>{
        let obj = {}
        obj.title = this.language=="CN"?i.meta.name:i.meta.nameEn
        obj.type = i.meta.type
        obj.path = i.path
        if(i.children){
          obj.children = this.menusFilter(i)
        }
        return obj
      })
      return arr
    },
    caidan(index){
      if(index==0 && this.clickTimes<=100){
        this.clickTimes += 1
        return
      }
      // this.$message.warning("你瞧瞧这给首页敲的，脸都绿了")
    },
    goto(menu){
      if(menu.type==10){
        window.open(menu.path)
        return
      }
      if(menu.initialPosition){
        this.$router.push({
          path:"/",
          query:{
            initialPosition:menu.initialPosition
          }
        })
      }else {
        if(menu.children){
          this.$router.push(menu.children[0].path)
        }else {
          this.$router.push(menu.path)
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
.GlobalFooter{
  background: #EDEEF2;
  //padding: 0 300px;
  .top{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom:48px;
    //width: 100vw;
    .QR{
      flex-shrink: 0;
      display: inline-block;
      text-align: center;
      padding-top: 24px;
      max-width: 180px;
      .QRimage{
        background: #FFFFFF;
        padding: 9px;
      }
      .text{
        margin-top: 12px;
        font-family: 'Source Han Sans CN';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        width: 100%;
      }
    }
    .menus{
      display: flex;
      padding-top: 24px;
      //flex-wrap: wrap;
      max-width:100vw;
      flex-shrink: 0;
      &-item{
        //flex: 1;
        flex-shrink: 0;
        //flex-grow: 1;
        //width: 0;
        padding: 0 50px;
        border-right: 1px solid #D7D7D9;
        &:last-child{
          border-right: none;
        }
        .menu{
          font-family: 'Source Han Sans CN';
          font-style: normal;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          cursor: default;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width:calc(1200px / 7 - 100px);
        }
        .submenu{
          margin-top:12px;
          max-width:calc(1200px / 7 - 100px);
          &-item{
            margin-bottom: 8px;
            font-family: 'Source Han Sans CN';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #656566;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &-item:hover{
            color: #333333;
            cursor: pointer;
          }
        }
      }
    }
  }
  .bottom{
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    background: #E6E7EB;
    .bottom-top{
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .bottom-bot{
      display: flex;
      justify-content: center;
    }
    .LOGO{
      img{
        height: 24px;
      }
      // filter: brightness(0);
    }
    .right{
      margin-left: 12px;
      font-family: 'Source Han Sans CN';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

</style>
